

























































import { PropType } from 'vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import { RenderPoiSource, ImageHolderInterface, FeatureItemInterface } from '~/modules/amplience/types';
import { useWindowSize } from "@vueuse/core";

export default defineComponent({
  name: 'FeatureBlock',
  components: {
    SfButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: Array as PropType<FeatureItemInterface[]>,
      default: () => [],
    },
    ctaType: {
      type: String,
      default: '',
    },
    imageOrientation: {
      type: String,
      default: 'Horizontal'
    },
    itemBackgroundColor: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
  },
  setup(props) {
    const windowSize = useWindowSize({
      includeScrollbar : false
    });
    const styleWidth = computed(() => '--content-width:' + windowSize.width.value + 'px');

    const formatColor =  (color) => {
      return color?.toLowerCase().replace(/\s/g, '');
    }

    return {
      isBackgroundImage: (image: ImageHolderInterface): boolean => !!image?.poi?.image,
      isCallToAction: (item: FeatureItemInterface): boolean => !!(item.ctaTitle && item.ctaUrl),
      formatColor,
      styleWidth,
      horizontalSources: computed(() => ([
        {
          aspect: '13:8',
          height: 256,
          media: '(max-width: 576px)',
        },
        {
          aspect: '463:309',
          height: 309,
          media: '(min-width: 577px) and (max-width: 992px)',
        },
        {
          aspect: '421:280',
          height: props.content && props.content.length === 2 ? 280 : 214,
          media: '(min-width: 993px)',
        },
      ] as RenderPoiSource[])),
      verticalSources: computed(() => ([
        {
          aspect: '382:426',
          height: 426,
          media: '(max-width: 576px)',
        },
        {
          aspect: '463:513',
          height: 513,
          media: '(min-width: 577px) and (max-width: 992px)',
        },
        {
          aspect: '421:467',
          height: 467,
          media: '(min-width: 993px)',
        },
      ] as RenderPoiSource[])),
    };
  }
});
