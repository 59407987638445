import { render, staticRenderFns } from "./FeatureBlock.vue?vue&type=template&id=17009b55&scoped=true"
import script from "./FeatureBlock.vue?vue&type=script&lang=ts"
export * from "./FeatureBlock.vue?vue&type=script&lang=ts"
import style0 from "./FeatureBlock.vue?vue&type=style&index=0&id=17009b55&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/nuxt/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17009b55",
  null
  
)

export default component.exports