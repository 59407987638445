import { ref } from '@nuxtjs/composition-api';
import type { DAMImage, DAMImageMetadata } from '~/modules/amplience/types';
import { UseDAMImage } from '~/modules/amplience/composables/useDAMImage/useDAMImage';

export function useDAMImage(ctx): UseDAMImage {
  const image = ref<DAMImage | null>(null);
  const metadata = ref<DAMImageMetadata | null>(null);
  const { $vsf } = ctx;

  const fetch = async (imgUrl: string): Promise<any> => {
    image.value = await $vsf.$ampl.api.getAmplMediaItem(imgUrl);
    metadata.value = image?.value?.metadata;
  };

  return {
    image,
    metadata,
    fetch,
  }
}
